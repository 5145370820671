<template>
  <div v-if="isLoading" class="absolute z-10 top-0 md:top-4 xl:top-6 left-0 right-4 w-full h-9 mt-0.5 md:px-4 xl:px-6">
    <div class="flex justify-center px-2 items-center w-full h-full bg-gray-100 border-l-2 border-r-2 border-b">
      <loading class="text-blumine w-6 h-6" />
      <span class="ml-2 text-xs xl:text-sm"><span class="font-bold">Loading</span> ({{ date.format(start, 'YYYY-MM-DD HH:mm') }} &mdash; {{ date.format(end, 'YYYY-MM-DD HH:mm') }})</span>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/icons/Loading.vue'
import date from '@/helpers/date.js'

export default {
  name: 'logs-loading',

  props: ['isLoading', 'start', 'end'],

  components: {
    Loading,
  },

  setup() {
    return { date }
  }
}
</script>
