<template>
  <select-box v-model="state.machineId" @change="$emit('machineSelected', state.machineId ? parseInt(state.machineId) : null)">
    <option :value="null">All Machines</option>
    <option v-for="machine in machines" :key="machine.location_id" :value="machine.location_id">{{ machine.name }}</option>
  </select-box>
</template>

<script>
import { reactive } from '@vue/reactivity'
import SelectBox from '@/components/form/Select.vue'
import { watch } from '@vue/runtime-core'

export default {
  name: 'logs-machine-selector',

  components: {
    SelectBox,
  },

  props: ['machineId', 'machines'],

  setup(props) {
    const state = reactive({
      machineId: props.machineId,
      machines: props.machines
    })

    watch(
      () => props.machineId,
      (machineId) => state.machineId = machineId
    )

    watch(
      () => props.machines,
      () => state.machines = props.machines
    )

    return {
      state,
    }
  }
}
</script>
