<template>
  <layout>
    <div class="log-wrapper relative bg-white flex flex-col -mx-2 -mt-4 md:mt-0 md:p-4 xl:p-6 rounded-md shadow-sm">
      <logs-loading
        :is-loading="state.isLoading"
        :start="state.currentRangeInfo.start"
        :end="state.currentRangeInfo.end"
      />
      <div
        id="log-container"
        class="flex-1 relative log-scroll-wrapper overflow-y-auto bg-gray-50 border-2 border-gray-200"
      >
        <button
          class="bg-gray-100 px-2 w-full py-2 text-sm font-bold text-gray-900 hover:bg-gray-300 border-b"
          @click="loadOlder"
        >
          Load Older
        </button>

        <template
          v-for="(log, index) in state.logs"
          :key="index"
        >
          <div v-if="log.header">
            <div class="bg-gray-200 text-gray-900 font-semibold px-2 py-1 text-white text-xs xl:text-sm font-mono tracking-tight">
              {{ log.header }}
            </div>
          </div>
          <div
            :id="`log_${log.activity_id}`"
            ref="log"
            class="py-1 px-2 text-xs xl:text-sm font-mono tracking-tight"
            :class="{ ' border-b border-gray-200': index !== state.logs.length - 1 }"
          >
            <span class="text-gray-800">
              {{ state.isUTCFormatEnabled ? date.utcFormat(log.timestamp) : log.dateTime }}
            </span>
            <span
              class="ml-2 font-bold text-blumine cursor-pointer hover:text-shakespear"
              @click="state.machineId = log.location_id; getLogs()"
              v-html="highlightInString(log.name, state.query)"
            />&nbsp;
            <span>
              <span
                class="uppercase font-semibold"
                :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true }"
                v-html="highlightInString(log.event_type, state.query)"
              />&nbsp;
              <span
                class="font-semibold"
                :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true }"
                v-html="highlightInString(log.event, state.query)"
              />&nbsp;
              <span
                v-if="log.username"
                class="text-gray-800"
              > by
                <span
                  class="font-semibold text-shakespear"
                  v-html="highlightInString(log.username, state.query)"
                />
              </span>
            </span>
          </div>
        </template>

        <div
          v-if="state.logs.length === 0"
          class="py-4 px-2 text-sm font-bold text-center"
        >
          No Entries
        </div>

        <div
          v-if="state.isPollingEnabled"
          class="border-t border-dashed px-2 w-full py-2 text-sm font-bold text-gray-900 text-center"
        >
          ... Live Tail ...
        </div>
        <button
          v-else
          class="bg-gray-100 px-2 w-full py-2 text-sm font-bold text-gray-900 hover:bg-gray-300 border-t border-b"
          @click="loadNewer"
        >
          Load Newer
        </button>
      </div>

      <div class="bg-concrete px-1 py-2 md:p-2 border-2 border-gray-200">
        <div class="flex flex-wrap sm:flex-nowrap items-center">
          <logs-machine-selector
            class="order-1 max-w-180px"
            :machine-id="state.machineId"
            :machines="state.machines"
            @machine-selected="setMachineId"
          />

          <form
            class="relative sm:flex-1 w-full order-3 sm:order-2 sm:ml-1 mt-1 sm:mt-0 xl:ml-2"
            @submit.prevent="getLogs()"
          >
            <text-input
              v-model="state.query"
              placeholder="Search..."
              search
            />
            <button
              type="submit"
              class="text-lg absolute mt-0.5 top-1/2 left-1 transform -translate-y-1/2 material-icons text-gray-500"
            >
              search
            </button>
            <button
              v-if="state.query.length > 0"
              type="button"
              class="text-lg absolute mt-0.5 top-1/2 right-2 transform -translate-y-1/2 material-icons text-gray-500"
              @click="state.query = ''; getLogs()"
            >
              close
            </button>
          </form>

          <div class="flex flex-row items-center order-2 sm:order-3 sm:ml-4 ml-auto">
            <div
              v-if="state.isAdmin"
              class="flex flex-row items-center leading-none text-sm space-x-1.5"
            >
              <toggle
                v-model="state.isUTCFormatEnabled"
              />
              <span>UTC<span class="hidden md:inline"> Timestamps</span></span>
            </div>

            <Button
              type="button"
              class="w-11 ml-2 sm:ml-4"
              @click="state.isRangeModalOpen = true"
            >
              <span class="material-icons">date_range</span>
            </Button>

            <div v-if="state.isPollingEnabled">
              <Button
                type="button"
                class="w-11 ml-1"
                @click="state.isAutoScrollEnabled = !state.isAutoScrollEnabled"
              >
                <span
                  v-if="state.isAutoScrollEnabled"
                  class="flex items-center"
                >
                  <span class="material-icons text-white">pause</span>
                  <span class="sr-only">Pause</span>
                </span>
                <span
                  v-else
                  class="flex items-center"
                >
                  <span class="material-icons">play_arrow</span>
                  <span class="sr-only">Play</span>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-2 bg-white h-10">
        <export-action-buttons
          :data="exportLogs"
          :filter="exportFilterDesc"
          filename="MachinesLogs"
        />
      </div>
    </div>

    <modal
      v-if="state.isRangeModalOpen"
      :is-modal-open="state.isRangeModalOpen"
      @close="close()"
    >
      <h3 class="text-lg font-bold -mt-2">
        Select Date &amp; Time Range
      </h3>
      <div class="block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
          aria-label="Close"
          @click="state.isRangeModalOpen = false"
        >
          <icon-x class="w-6 h-6" />
        </button>
      </div>
      <form
        class="flex flex-wrap items-center mt-3"
        novalidate
        @submit.prevent="applyRange()"
      >
        <date-range-picker
          v-model="state.range"
          :with-range-selector="false"
          :max-range="30"
        />

        <div class="flex w-full space-x-2 mt-2">
          <time-picker
            v-model="state.time.from"
            label="Time From"
            class="w-1/2"
          />
          <time-picker
            v-model="state.time.to"
            label="Time To"
            class="w-1/2"
          />
        </div>

        <div class="flex space-x-2 mt-4">
          <Button
            type="button"
            class="space-x-1 bg-opacity-50"
            @click="resetRange()"
          >
            <span class="material-icons text-xl">restart_alt</span>
            <span>Reset</span>
          </Button>

          <Button type="submit">
            Apply
          </Button>
        </div>
      </form>
    </modal>
  </layout>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Layout from '@/layouts/Default.vue'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import { isNumeric } from '@/helpers/utils.js'
import { store } from '@/services/store.js'
import { highlightInString, debounce } from '@/helpers/utils.js'
import LogsLoading from '@/components/logs/LogsLoading.vue'
import LogsMachineSelector from '@/components/logs/LogsMachineSelector.vue'
import TextInput from '@/components/form/Text.vue'
import Button from '@/components/form/Button.vue'
import DateRangePicker from '@/components/form/DateRange.vue'
import Modal from '@/components/Modal.vue'
import { IconX } from '@/components/icons'
import TimePicker from '@/components/form/TimePicker.vue'
import Toggle from '@/components/form/Toggle.vue'
import ExportActionButtons from '@/components/datatables/ActionButtons.vue'

export default {
  name: 'MachinesDetailLog',

  components: {
    Layout,
    TextInput,
    DateRangePicker,
    Button,
    Modal,
    IconX,
    LogsLoading,
    LogsMachineSelector,
    TimePicker,
    Toggle,
    ExportActionButtons,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    let now = date.now()
    let pollingInterval = null
    const log = ref()
    const cancelSource = ref()

    const state = reactive({
      logs: [],
      isLoading: false,
      query: '',
      start: now.subtract(48, 'hours').unix(),
      end: now.unix(),
      machineId: store.selectedMachineId,
      isRangeSet: false,
      isRangeModalOpen: false,
      range: {
        from: now.subtract(48, 'hours').format('YYYY-MM-DD'),
        to: now.format('YYYY-MM-DD'),
      },
      time: {
        from: '00:00',
        to: '23:59',
      },
      isPollingEnabled: true,
      isAutoScrollEnabled: true,
      isScrollListenerEnabled: true,
      isUTCFormatEnabled: false,
      isAdmin: false,
      machines: [],
      visibleRange: {
        start: null,
        end: null,
      },
      currentRangeInfo: {
        start: null,
        end: null,
      },
      loadOlderRange: null,
      loadNewerRange: null,
    })

    // null saved machine id
    store.selectedMachineId = null

    if (route.query.from && route.query.to) {
      // isNumeric - backward support for old unix timestamps
      const from = isNumeric(route.query.from) ? route.query.from : date.instance(route.query.from).unix()
      const to = isNumeric(route.query.to) ? route.query.to : date.instance(route.query.to).unix()

      state.isRangeSet = true
      state.isPollingEnabled = false
      state.isAutoScrollEnabled = false
      state.start = from
      state.end = to

      state.range.from = date.format(from, 'YYYY-MM-DD')
      state.range.to = date.format(to, 'YYYY-MM-DD')

      state.time.from = date.format(from, 'HH:mm')
      state.time.to = date.format(to, 'HH:mm')
    }

    if (route.query.locationid) {
      state.machineId = parseInt(route.query.locationid)
    }

    if (route.query.q) {
      state.query = route.query.q
    }

    state.visibleRange = {
      start: state.start,
      end: state.end,
    }

    const resetRange = () => {
      state.isRangeSet = false
      state.isRangeModalOpen = false
      state.range.from = now.subtract(48, 'hours').format('YYYY-MM-DD')
      state.range.to = now.format('YYYY-MM-DD')
      state.time.from = '00:00'
      state.time.to = '23:59'

      updateQuery()

      applyFilters()
    }

    const applyRange = () => {
      state.isRangeSet = true
      state.isRangeModalOpen = false

      applyFilters()
    }

    const setMachineId = (machineId) => {
      state.machineId = machineId
      updateQuery()
      getLogs()
    }

    const updateQuery = () => {
      let queryParams = {
        from: date.formatTimestampToISO(state.visibleRange.start),
        to: date.formatTimestampToISO(state.visibleRange.end),
      }

      if (state.machineId) {
        queryParams.locationid = state.machineId
      }

      if (state.query) {
        queryParams.q = state.query
      }

      router.push({ query: queryParams }, undefined, { shallow: true })
    }

    const applyFilters = () => {
      state.loadOlderRange = null
      state.loadNewerRange = null

      if (state.isRangeSet) {
        state.isPollingEnabled = false
        state.isAutoScrollEnabled = false
        state.isRangeSet = true
        state.start = date.instance(`${state.range.from} ${state.time.from}:00`).utc().unix()
        state.end = date.instance(`${state.range.to} ${state.time.to}:59`).utc().unix()
        state.visibleRange = { start: state.start, end: state.end }
        updateQuery()
        getLogs()
      } else {
        state.isPollingEnabled = true
        resetStartEndInterval()
        getLogs()
      }
    }

    const resetStartEndInterval = () => {
      now = date.now()
      state.start = now.subtract(12, 'hours').unix()
      state.end = now.unix() + 10000

      state.visibleRange = {
        start: state.start,
        end: state.end,
      }
    }

    const getLogs = (isPolling = false) => {
      if (!isPolling) {
        state.isScrollListenerEnabled = false
        state.logs = []
        if (! state.isRangeSet) resetStartEndInterval()
      }
      getLogsByStartEnd(state.start, state.end, isPolling)
    }

    const getLogsByStartEnd = async (start, end, isPolling = false, loop = null) => {
      if (loop < 0) {
        end = start
        start = parseInt(start) + (loop*60*60)
      }
      else if (loop > 0) {
        start = end
        end = parseInt(end) + (loop*60*60)
      }
      // console.log('times', state.start, state.end)

      if (! isPolling) {
        if (pollingInterval) stopPollLogs()
        state.isLoading = true
      }

      try {
        state.currentRangeInfo = { start, end }

        if (cancelSource.value) {
          cancelSource.value.cancel()
        }
        cancelSource.value = http.CancelToken.source()
        const { data: entries } = await http.get(
          `logs?start=${start}&end=${end}&q=${state.query}&location_ids=${state.machineId || ''}`,
          {
            cancelToken: cancelSource.value.token
          }
        )

        const logsLength = state.logs.length

        let firstMessageId, offset
        if (logsLength > 0) {
          firstMessageId = state.logs[0].activity_id
          offset = document.getElementById('log_' + firstMessageId).offsetTop - document.getElementById('log-container').scrollTop;
        }

        addEntries(entries)

        if (loop < 0) {
          state.loadOlderRange = { start, end }
        }
        else if (loop > 0) {
          state.loadNewerRange = { start, end }
        }

        state.visibleRange = {
          start: (start < state.visibleRange.start) ? start :  state.visibleRange.start,
          end: (end > state.visibleRange.end) ? end :  state.visibleRange.end,
        }

        if (loop && entries.length === 0) {
          const newLoop = loop*2
          if (Math.abs(newLoop) <= 64) {
            getLogsByStartEnd(start, end, false, newLoop)
            return
          }
        }

        if (loop < 0 && entries.length > 0) {
          setTimeout(() => {
            const top = document.getElementById('log_' + firstMessageId).offsetTop - offset
            document.getElementById('log-container').scroll(0, top)
          }, 10)
        }

        if (logsLength !== state.logs.length && !loop && state.isAutoScrollEnabled) scrollToLastLogItem()

        if (! pollingInterval && state.isPollingEnabled) startPollLogs()

        state.isLoading = false
        state.isScrollListenerEnabled = true

        updateQuery()
      } catch (e) {
        if (!http.isCancel(e)) {
          state.isLoading = false
          alert(e.response.data.Message)
        }
      }
    }

    const addEntries = (entries) => {
      entries.forEach((log) => {
        if (state.logs.find((l) => l.activity_id === log.activity_id)) return
        state.logs.push({
          ...log,
          dateTime: date.logFormat(log.timestamp),
        })
      })

      state.logs.sort((x, y) => x.timestamp - y.timestamp)

      // Day headers
      const usedHeaders = []
      const hasHeader = (ts) => {
        const day = date.format(ts, 'YYYY-MM-DD')
        if (usedHeaders.includes(day)) {
          return null
        }
        else {
          usedHeaders.push(day)
          return date.format(ts, 'D. MMM YYYY')
        }
      }
      state.logs = state.logs.map(entry => ({
        ...entry,
        header: hasHeader(entry.timestamp)
      }))
    }

    const loadOlder = () => {
      if (state.isLoading) return

      if (!state.loadOlderRange) {
        if (state.logs.length > 0) {
          const lastItemTimestamp = parseInt(state.logs[0].timestamp)
          state.loadOlderRange = {
            start: lastItemTimestamp,
            end: lastItemTimestamp + 1
          }
        }
        else {
          state.loadOlderRange = {
            start: state.start,
            end: state.end
          }
        }
      }
      getLogsByStartEnd(state.loadOlderRange.start, state.loadOlderRange.end, false, -4)
    }

    const loadNewer = () => {
      if (state.isLoading) return

      if (!state.loadNewerRange) {
        if (state.logs.length > 0) {
          const lastItemTimestamp = parseInt(state.logs[state.logs.length - 1].timestamp)
          state.loadNewerRange = {
            start: lastItemTimestamp,
            end: lastItemTimestamp + 1
          }
        }
        else {
          state.loadNewerRange = {
            start: state.start,
            end: state.end
          }
        }
      }
      getLogsByStartEnd(state.loadNewerRange.start, state.loadNewerRange.end, false, 4)
    }

    const getMachines = async () => {
      const { data } = await http.get('user/machines')
      state.machines = data.allowed_locations
    }

    const startPollLogs = () => {
      pollingInterval = setInterval(() => {
        if (state.logs.length > 0) {
          const lastItemTimestamp = parseFloat(state.logs[state.logs.length - 1].timestamp)
          state.start = lastItemTimestamp - 6
          state.end = state.end ? state.end + 10 : lastItemTimestamp + 10000
        } else {
          resetStartEndInterval()
        }

        getLogs(true)
      }, 10000)
    }

    const stopPollLogs = () => {
      clearInterval(pollingInterval)
      pollingInterval = null
    }

    const scrollToLastLogItem = () => {
      setTimeout(() => {
        const element = document.getElementById('log-container')
        element.scrollTop = element.scrollHeight
      }, 100)
    }

    const handleScroll = (event) => {
      if (!state.isScrollListenerEnabled) return

      const element = event.target
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        state.isAutoScrollEnabled = true
        if (!state.isPollingEnabled) {
          loadNewer()
        }
      }
      else {
        state.isAutoScrollEnabled = false
      }

      if (element.scrollTop === 0) {
        loadOlder()
      }
    }

    const getUserAndSetIsAdmin = async () => {
      const { data } = await http.get('user/profile')

      state.isAdmin = data.is_administrator
    }

    // Export
    const structure = [
      { title: 'Time', key: 'dateTime' },
      { title: 'Machine', key: 'name' },
      { title: 'Event Type', key: 'event_type' },
      { title: 'Event', key: 'event' },
    ]

    const exportLogs = computed(() => {
      const result = []
      state.logs.forEach((items, rowIndex) => {
        structure.forEach((column, index) => {
          const data = {
            row: index,
            title: column.title,
            value: items[column.key],
          }
          result[rowIndex] ? result[rowIndex].push(data) : result.push([data])
        })
      })
      return result
    })

    const exportFilterDesc = computed(() => {
      let value = ''
      if (state.query) value += `Query: ${state.query}\n`
      if (state.machineId) {
        const machine = state.machines.find(machine => machine.location_id === state.machineId)
        value += `Machine: ${machine ? machine.name : state.machineId}\n`
      }
      if (state.isRangeSet) {
        value += `Date Range: ${state.range.from} ${state.time.from} - ${state.range.to} ${state.time.to}`
      }
      if (value.length > 0) value = 'Active Filter\n' + value
      return value
    })
    // /Export

    onMounted(() => {
      document.getElementById('log-container').addEventListener('scroll', handleScroll)
      window.Beacon('destroy')
    })

    onBeforeUnmount(() => {
      if (cancelSource.value) {
        cancelSource.value.cancel()
      }
      document.getElementById('log-container').removeEventListener('scroll', handleScroll)
      stopPollLogs()
    })

    getLogs()

    getUserAndSetIsAdmin()

    getMachines()

    watch(
      () => state.query,
      () => updateQuery()
    )

    watch(
      () => route.query,
      () => {
        // clicked on Logs in menu - reset Range
        if (route.name === 'Logs' && Object.keys(route.query).length === 0) {
          state.query = ''
          state.machineId = null
          resetRange()
        }
      }
    )

    return {
      date,
      log,
      getLogs,
      state,
      highlightInString,
      debounce,
      applyRange,
      resetRange,
      applyFilters,
      setMachineId,
      exportLogs,
      exportFilterDesc,

      loadOlder,
      loadNewer,
    }
  }
}
</script>
